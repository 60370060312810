<template>
  <div class="floating-select-field">
    <b-form-select
      class="form-control time-picker"
      :class="{
        'is-value-exist': value
      }"
      :id="id"
      v-on="$listeners"
      :value="value"
      @input="$emit('update', $event)"
    >
      <b-form-select-option
        v-for="(time, index) in timeOptionsList"
        :key="index"
        :value="time.value"
        :disabled="disableTime(time.value)"
      >
        {{ time.label }}</b-form-select-option
      >
    </b-form-select>
    <label :for="id">{{ label }} </label>
    <BIconClock
      font-scale="1.25"
      aria-hidden="true"
      class="input-placeholder-icon"
    />
    <a
      href="javascript:void(0)"
      role="button"
      class="color-black"
      @click="$emit('update', '')"
    >
      <BIconX
        v-if="value"
        scale="1.5"
        aria-hidden="true"
        class="input-placeholder-icon remove-selected-value"
    /></a>
  </div>
</template>
<script>
import { BIconClock, BIconX } from 'bootstrap-vue'
export default {
  name: 'TimePicker',
  props: ['id', 'label', 'value', 'disable-times'],
  model: {
    prop: 'value',
    event: 'update'
  },
  data () {
    return {
      timeOptionsList: [
        {
          label: '12:00 AM',
          value: '00:00'
        },
        {
          label: '12:30 AM',
          value: '00:30'
        },
        {
          label: '01:00 AM',
          value: '01:00'
        },
        {
          label: '01:30 AM',
          value: '01:30'
        },
        {
          label: '02:00 AM',
          value: '02:00'
        },
        {
          label: '02:30 AM',
          value: '02:30'
        },
        {
          label: '03:00 AM',
          value: '03:00'
        },
        {
          label: '03:30 AM',
          value: '03:30'
        },
        {
          label: '04:00 AM',
          value: '04:00'
        },
        {
          label: '04:30 AM',
          value: '04:30'
        },
        {
          label: '05:00 AM',
          value: '05:00'
        },
        {
          label: '05:30 AM',
          value: '05:30'
        },
        {
          label: '06:00 AM',
          value: '06:00'
        },
        {
          label: '06:30 AM',
          value: '06:30'
        },
        {
          label: '07:00 AM',
          value: '07:00'
        },
        {
          label: '07:30 AM',
          value: '07:30'
        },
        {
          label: '08:00 AM',
          value: '08:00'
        },
        {
          label: '08:30 AM',
          value: '08:30'
        },
        {
          label: '09:00 AM',
          value: '09:00'
        },
        {
          label: '09:30 AM',
          value: '09:30'
        },
        {
          label: '10:00 AM',
          value: '10:00'
        },
        {
          label: '10:30 AM',
          value: '10:30'
        },
        {
          label: '11:00 AM',
          value: '11:00'
        },
        {
          label: '11:30 AM',
          value: '11:30'
        },
        {
          label: '12:00 PM',
          value: '12:00'
        },
        {
          label: '12:30 PM',
          value: '12:30'
        },
        {
          label: '01:00 PM',
          value: '13:00'
        },
        {
          label: '01:30 PM',
          value: '13:30'
        },
        {
          label: '02:00 PM',
          value: '14:00'
        },
        {
          label: '02:30 PM',
          value: '14:30'
        },
        {
          label: '03:00 PM',
          value: '15:00'
        },
        {
          label: '03:30 PM',
          value: '15:30'
        },
        {
          label: '04:00 PM',
          value: '16:00'
        },
        {
          label: '04:30 PM',
          value: '16:30'
        },
        {
          label: '05:00 PM',
          value: '17:00'
        },
        {
          label: '05:30 PM',
          value: '17:30'
        },
        {
          label: '06:00 PM',
          value: '18:00'
        },
        {
          label: '06:30 PM',
          value: '18:30'
        },
        {
          label: '07:00 PM',
          value: '19:00'
        },
        {
          label: '07:30 PM',
          value: '19:30'
        },
        {
          label: '08:00 PM',
          value: '20:00'
        },
        {
          label: '08:30 PM',
          value: '20:30'
        },
        {
          label: '09:00 PM',
          value: '21:00'
        },
        {
          label: '09:30 PM',
          value: '21:30'
        },
        {
          label: '10:00 PM',
          value: '22:00'
        },
        {
          label: '10:30 PM',
          value: '22:30'
        },
        {
          label: '11:00 PM',
          value: '23:00'
        },
        {
          label: '11:30 PM',
          value: '23:30'
        }
      ]
    }
  },
  components: {
    BIconClock,
    BIconX
  },
  computed: {
    disableTime () {
      return time => {
        if (this.disableTimes) {
          if (this.disableTimes.hasOwnProperty('end')) {
            return Number(time.replace(/:/g, '')) <= this.disableTimes.end
          }
          if (this.disableTimes.hasOwnProperty('start')) {
            return Number(time.replace(/:/g, '')) >= this.disableTimes.start
          }
        }
        return false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.time-picker {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: none !important;
}
.remove-selected-value {
  right: 2.5rem;
  top: 52%;
}
</style>
