<template>
  <b-form @submit.prevent="onSubmitSearch" autocomplete="off" novalidate>
    <b-form-row class="custom-row">
      <b-col>
        <b-form-group>
          <div class="floating-multi-select-field">
            <treeselect
              v-model="filterForm.location"
              :multiple="true"
              :options="recreationWidgetLocations"
              :disableFuzzyMatching="true"
              :normalizer="normalizer"
              :class="{ 'is-value-exist': filterForm.location.length }"
            />
            <label class="placeholder-label" for="location">{{ translateLanguage('Txt_Recreation_widget_Select_location') }}</label>
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="custom-row" v-if="showAdvancedFilters">
      <b-col :lg="isFacilityBookingClass">
        <b-form-row class="custom-row">
          <b-col lg="6" class="line-connect">
            <b-form-group>
              <div class="floating-input-field">
                <datepicker
                  id="start-date"
                  :bootstrapStyling="true"
                  :typeable="false"
                  :highlighted="highlighted"
                  :disabled-dates="disableStartDate"
                  :wrapperClass="{
                    'is-value-exist': filterForm.startDate,
                  }"
                  v-model="filterForm.startDate"
                  format="MM/dd/yyyy"
                >
                </datepicker>
                <label for="start-date"> {{ translateLanguage('Txt_Recreation_widget_Start_date') }} </label>
                <BIconCalendar4 font-scale="1.2" aria-hidden="true" class="input-placeholder-icon" />
                <a
                  href="javascript:void(0)"
                  role="button"
                  class="color-black input-placeholder-icon remove-selected-value"
                  @click="filterForm.startDate = null"
                >
                  <BIconX v-if="filterForm.startDate" scale="1.5" aria-hidden="true"
                /></a>
              </div>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group>
              <div class="floating-input-field">
                <datepicker
                  id="end-date"
                  :bootstrapStyling="true"
                  :typeable="false"
                  :highlighted="highlighted"
                  :disabled-dates="disableEndDate"
                  :wrapperClass="{
                    'is-value-exist': filterForm.endDate,
                  }"
                  v-model="filterForm.endDate"
                  format="MM/dd/yyyy"
                >
                </datepicker>
                <label for="end-date">{{ translateLanguage('Txt_Recreation_widget_End_date') }} </label>
                <BIconCalendar4 font-scale="1.2" aria-hidden="true" class="input-placeholder-icon" />
                <a href="javascript:void(0)" role="button" class="color-black input-placeholder-icon remove-selected-value" @click="filterForm.endDate = null">
                  <BIconX v-if="filterForm.endDate" scale="1.5" aria-hidden="true"
                /></a>
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-col>
      <b-col lg="6" v-if="calendarType !== recreationBookingEnums.FACILITYBOOKING">
        <b-form-row class="custom-row">
          <b-col lg="6" class="line-connect">
            <b-form-group>
              <TimePicker
                id="start-time"
                :label="translateLanguage('Txt_Recreation_widget_Start_time')"
                :disable-times="disableStartTimes"
                v-model="filterForm.startTime"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group>
              <TimePicker
                id="end-time"
                :label="translateLanguage('Txt_Recreation_widget_End_time')"
                :disable-times="disableEndTimes"
                v-model="filterForm.endTime"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-col>
    </b-form-row>
    <b-form-row class="custom-row">
      <b-col>
        <b-form-group>
          <a
            class="color-black advanced-filters-toggle text-decoration-none"
            role="button"
            href="javascript:void(0)"
            @click="showAdvancedFilters = !showAdvancedFilters"
          >
            <span v-if="showAdvancedFilters">
              <MinusSquareBlack aria-hidden="true" class="mr-2 mt-n05" />
              {{ translateLanguage('Lnk_Recreation_widget_Hide_adv_filters') }}
            </span>
            <span v-else>
              <PlusSquareBlack aria-hidden="true" class="mr-2 mt-n05" />
              {{ translateLanguage('Lnk_Recreation_widget_Adv_filters') }}
            </span>
          </a>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-3 custom-row">
      <b-col>
        <b-button class="btn-theme-height btn-black w-150px mr-2" type="submit" :disabled="!isFormEmpty"
          >{{ translateLanguage('Btn_Recreation_widget_Apply_filters') }}
        </b-button>
        <b-button variant="primary" class="btn-theme-height btn-white w-150px" @click="resetFilters">
          {{ translateLanguage('Btn_Recreation_widget_Reset_filters') }}
        </b-button>
      </b-col>
    </b-form-row>
    <b-form-row v-if="getErrorMsg" class="error-message">
      <b-col>
        <b-alert variant="danger" show><BIconExclamationCircle scale="1.3" class="mr-2" />{{ getErrorMsg }}</b-alert>
      </b-col>
    </b-form-row>
  </b-form>
</template>
<script>
import { mapState } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { BIconCalendar4, BIconExclamationCircle, BIconX } from 'bootstrap-vue'
import TimePicker from '../../common/TimePicker.vue'
import moment from 'moment'
import Treeselect from '@riophae/vue-treeselect'
import cloneDeep from 'lodash/cloneDeep'
import PlusSquareBlack from '../../../assets/svg/plus-square-black.svg'
import MinusSquareBlack from '../../../assets/svg/minus-square-black.svg'
import { RecreationBookingEnums } from '../../../utilities/constants'
export default {
  name: 'FIlterForm',
  props: {
    'search-type': {
      type: String,
      required: true,
    },
    'calendar-type': {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      recreationClassesSearchDuration: Number(process.env.VUE_APP_RECREATIONCLASSESSEARCHDURATION) || 60,
      showAdvancedFilters: false,
      momentDateFormat: 'YYYY-MM-DD',
      filterForm: {
        location: [],
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      },
      showSearchResult: true,
      highlighted: {
        dates: [new Date()],
      },
      normalizer(node) {
        return {
          id: node.locationId,
          label: node.name,
        }
      },
      recreationBookingEnums: RecreationBookingEnums,
    }
  },
  components: {
    Datepicker,
    TimePicker,
    Treeselect,
    BIconCalendar4,
    BIconExclamationCircle,
    BIconX,
    PlusSquareBlack,
    MinusSquareBlack,
  },
  computed: {
    isFacilityBookingClass() {
      return this.calendarType === this.recreationBookingEnums.FACILITYBOOKING ? 12 : 6
    },
    ...mapState({
      recreationWidgetLocations: (state) => state.shared.recreationWidgetLocations,
      recreationWidgetFilterForm: (state) => state.shared.recreationWidgetFilterForm,
    }),
    getErrorMsg() {
      if (this.filterForm.startTime && this.filterForm.endTime) {
        const today = moment().format(this.momentDateFormat)
        const startDate = this.filterForm.startDate ? moment(this.filterForm.startDate).format(this.momentDateFormat) : today
        const endDate = this.filterForm.endDate ? moment(this.filterForm.endDate).format(this.momentDateFormat) : today
        if ((this.filterForm.startDate && this.filterForm.endDate) || (!this.filterForm.startDate && !this.filterForm.endDate)) {
          if (moment(`${startDate}T${this.filterForm.startTime}`).isAfter(moment(`${endDate}T${this.filterForm.endTime}`))) {
            return 'End time should be greater than start time.'
          }
        }
      }
      return false
    },
    disableStartDate() {
      const disableDates = {}
      if (this.filterForm.endDate) {
        const formatEndDate = moment(this.filterForm.endDate).format(this.momentDateFormat)
        disableDates.to = moment(formatEndDate)
          .subtract(this.recreationClassesSearchDuration - 1, 'day')
          .toDate()
        disableDates.from = moment(formatEndDate).add(1, 'days').toDate()
      }
      return disableDates
    },
    disableEndDate() {
      const today = moment().format(this.momentDateFormat)
      const disableDates = {
        to: moment(today).toDate(),
      }
      if (this.filterForm.startDate) {
        const formatStartDate = moment(this.filterForm.startDate).format(this.momentDateFormat)
        const isAfter = moment(disableDates.to).isAfter(moment(formatStartDate))
        disableDates.to = isAfter ? disableDates.to : moment(formatStartDate).toDate()
        disableDates.from = moment(formatStartDate).add(this.recreationClassesSearchDuration, 'day').toDate()
      }
      return disableDates
    },
    disableStartTimes() {
      if (moment(this.filterForm.startDate).format(this.momentDateFormat) === moment(this.filterForm.endDate).format(this.momentDateFormat)) {
        if (this.filterForm.endTime) {
          return { start: Number(this.filterForm.endTime.replace(/:/g, '')) }
        }
      }
      return null
    },
    disableEndTimes() {
      if (moment(this.filterForm.startDate).format(this.momentDateFormat) === moment(this.filterForm.endDate).format(this.momentDateFormat)) {
        if (this.filterForm.startTime) {
          return { end: Number(this.filterForm.startTime.replace(/:/g, '')) }
        }
      }
      return null
    },
    isFormEmpty() {
      return Object.values(this.filterForm).some((x) => (Array.isArray(x) ? x.length : x))
    },
  },
  mounted() {
    this.assignFilterForm()
  },
  methods: {
    assignFilterForm() {
      if (this.recreationWidgetFilterForm) {
        this.filterForm = cloneDeep(this.recreationWidgetFilterForm)
        const isFormEmptyWithoutLocation = Object.values(this.filterForm)
          .filter((field) => field !== 'location')
          .some((x) => x)
        this.showAdvancedFilters = isFormEmptyWithoutLocation
        if (this.searchType === 'classes') {
          this.storeFilterForm()
        }
      }
    },
    storeFilterForm(form) {
      const data = Object.assign({}, { hasError: false, showSearchResult: this.showSearchResult })
      this.$store.commit('shared/setRecreationWidgetFilterFormError', data)
      if (this.searchType === 'categories') {
        this.$store.commit('shared/setRecreationWidgetFilterForm', cloneDeep(form))
        return
      }
      if (this.searchType === 'classes') {
        this.$store.commit('shared/setRecreationWidgetClassesFilterForm', cloneDeep(form))
      }
    },
    onSubmitSearch() {
      if (!this.getErrorMsg) {
        this.showSearchResult = true
        this.storeFilterForm(this.filterForm)
      }
    },
    resetFilters() {
      this.showAdvancedFilters = false
      this.filterForm = {
        location: [],
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      }
      this.showSearchResult = true
      this.storeFilterForm(null)
    },
  },
  watch: {
    getErrorMsg(newValue) {
      this.showSearchResult = false
      const data = Object.assign({}, { hasError: !!newValue, showSearchResult: this.showSearchResult })
      this.$store.commit('shared/setRecreationWidgetFilterFormError', data)
    },
  },
}
</script>
<style lang="scss" scoped>
.custom-row {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  .col,
  [class*='col-'] {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
.remove-selected-value {
  right: 2.5rem;
  top: 52%;
}
</style>
